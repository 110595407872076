





































































import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    StakeResult: () => import("../components/stake-result.vue"),
  },
})
export default class IncreaseStakeDialog extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;

  walletStore = walletStore;

  get estimatedBooster() {
    return this.vmEvent.selectingBooster.multiplier;
  }

  get estimatedAPR() {
    return this.vmEvent.userApy.mulUnsafe(FixedNumber.from(this.estimatedBooster + ""));
  }
}
